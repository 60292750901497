import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { mapState } from 'vuex'

@Component({
  name: 'ChangeLog',
  computed: {
    ...mapState('event', ['event'])
  }
})
export default class ChangeLog extends Vue {
  @Prop({ required: true, type: Array })
  changelog: Record<string, any>[]|undefined

  @Prop({ required: true, type: String })
  event_uuid: string|undefined

  @Prop({ required: true })
  content_uuid_to_deploy_live: any|undefined

  @Prop({ required: true, type: String })
  type: string|undefined

  @Prop({ required: true, type: String })
  sub_type: string|undefined

  @Prop({ required: true })
  dev_uuid: any;

  @Prop({ required: true })
  live_uuid: string|undefined

  @Prop({ required: false })
  permission: string|undefined

  data () {
    return {
      table: {
        headers: [
          { text: 'Revision', align: 'start', sortable: false, value: 'revision' },
          { text: 'Date', align: 'center', sortable: false, value: 'date' }
        ],
        items: []
      }
    }
  }

  @Watch('changelog', { immediate: true })
  onChangelogChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  @Watch('content_uuid_to_deploy_live')
  onContentUuidToDeployLiveChange (payload: string) {
    this.$data.version = payload
  }

  handleVersionChange (payload) {
    if (payload) {
      this.$emit('changelog-clicked', payload.uuid)
    }
  }

  get version () {
    return this.dev_uuid
  }

  // handles deploying a revision.
  async handleDeployLive () {
    try {
      // as long as event_uuid is set.
      if (this.event_uuid !== undefined) {
        const content_uuid = this.version ? this.version : this.content_uuid_to_deploy_live
        // build the deploy payload.
        const payload = {
          type: this.type,
          sub_type: this.sub_type,
          event_uuid: this.event_uuid,
          data: {
            content_uuid
          }
        }
        // send deploy live call.
        await this.$store.dispatch('event/deployLive', payload)
        this.$emit('deployed')
        // Notify the user that deployment was successful.
        Container.get(Notification).success('Content deployed live.')
      }
    } catch (error) {
      // throw an error if any errors occur.
      Container.get(ErrorHandlerService).error(error)
    }
  }
}
